import React from "react"
import addressIcon from "../data/SVG/address-location.svg"

export default function ContactInfo({ className }) {
  return (
    <div className={className + ` fl shadow-2 pv5 br2`}>
      <div className="flex flex-row justify-center items-center f5 f4-l">
        <img className="filtered-blue w-20-l w-30-m w-20 pa4 ma2" src={addressIcon} alt="Laser eye" />
        <div>
          <h2 className="pb3 turn-me-blue b">Our Info</h2>
          <p className="pb3">
            <a
              className="link black underline hover-black-70"
              href="https://goo.gl/maps/gLUJtfHbwCX49Yas9"
              target="__blank"
            >
              <p>8601 Warden Avenue</p>
              <p>Markham, ON L3R 2L6</p>
            </a>
          </p>
          <p className="mb2">
            {" "}
            Tel:{" "}
            <a className=" underline hover-black-70 link black" href="tel:905-940-9988">
              (905) 940-9988
            </a>
          </p>
          <p>
            Email:{" "}
            <a className=" underline hover-black-70 link black" href="mailto:office@drwiner.ca" target="__blank">
              office@drwiner.ca
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
