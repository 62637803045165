import React from "react"
import styled from "styled-components"

const InputField = styled.input`
    :hover {
      background-color: #c2efff;;
    }
`
const TextArea = styled.textarea`
    :hover {
      background-color: #c2efff;
    }
`
const Button = styled.button`
    :hover {
      background-color: #009DD2;
      color: white;
      border: solid 1px #009DD2;
    }
`
const InputSubmit = styled.input`
    :hover {
      background-color: #009DD2;
      color: white;
      border: solid 1px #009DD2;
    }
`

export default function ContactForm() {

  return (
    <div className='dib tl w-50-l w-100-m w-100 bg-white pa4 ma4-l ma3-m ma2'>
      <form method="post" 
        netlify-honeypot="bot-field" 
        data-netlify="true" 
        name="contact">  
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        
        <h2 className='mb2'>Contact Form</h2>
        <label htmlFor="name" className="db lh-copy f6"  >
          Name
          <InputField className="br1 pa2 input-reset ba bg-transparent hover-bg-lightest-blue w-100" type="text" name="name" id="name" />
        </label>
        <label htmlFor="email" className="db lh-copy f6" >
          Email
          <InputField className="br1 pa2 input-reset ba bg-transparent w-100" type="email" name="email" id="email" />
        </label>
        <label htmlFor="subject" className="db lh-copy f6">
          Subject
          <InputField className="br1 pa2 input-reset ba bg-transparent w-100" type="text" name="subject" id="subject" />
        </label>
        <label htmlFor="message" className="db lh-copy f6">
          Message
          <TextArea className="db border-box br1 pa2 input-reset ba bg-transparent w-100" name="message" id="message" rows="5" />
        </label>
        <Button className='br1 mr3 mt3 b ph3 pv2 input-reset ba b--black bg-transparent pointer f6 dib' type="submit">Send</Button>
        <InputSubmit className='br1 mr3 mt3 b ph3 pv2 input-reset ba b--black bg-transparent pointer f6 dib' type="reset" value="Clear" />
        <small className="f7 black-60">We will respond as soon as we get this message!</small>
      </form>
    </div>
  )
}



        // <form className="br3 ba b--black-10 mv4 w-100 w-50-m mw6 shadow-5 center bg-white">
        //   <main className="pa4 black-80">
        //     <div className="measure">
        //       <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
        //         <legend className="f2 fw6 ph0 mh0">Contact us</legend>
        //         <div className="mt3">
        //           <label className="db fw6 lh-copy f6" htmlFor="name">Name
        //           <input 
        //             className="pa2 input-reset ba bg-transparent hover-bg-lightest-blue w-100"
        //             type="text" 
        //             name="name"  
        //             id="name" 
                    
        //           /></label>
        //         </div>
        //         <div className="mt3">
        //           <label className="db fw6 lh-copy f6" htmlFor="email-address">Email
        //           <input 
        //             className="pa2 input-reset ba bg-transparent hover-bg-lightest-blue w-100" 
        //             type="email" 
        //             name="email-address"  
        //             id="email-address" 
                    
        //           /></label>
        //         </div>
        //         <div className="mv3">
        //           <label className="db fw6 lh-copy f6" htmlFor="comment">Comment
        //           <textarea id="comment" name="comment" className="db border-box hover-bg-lightest-blue w-100 measure ba b--black-20 pa2 br2 mb2" aria-describedby="comment-desc"></textarea></label>
        //         </div>
        //       </fieldset>
        //       <div className="">
        //         <input
                 
        //         className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
        //         type="submit" 
        //         value="submit"/>
        //       </div>
    
        //     </div>
        //   </main>
        // </form>  