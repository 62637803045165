import React from "react"
import workingHours from '../../content/timeTable/index.json'


export default function WorkingHours({className}) {

  //console.log(workingHours)
  return (
    <div className={className}>
    	<h2 className='tc pb3'>Hours of operation</h2>
        <div className="overflow-auto">
          <table className="f6 f4-ns w-100 mw8 center" cellSpacing="0">
            <tbody className="lh-copy ">
              <tr className=" tc stripe-light ">
                <td className="pa3">Monday</td>
                <td className="pa3">{workingHours.monday}</td>
              </tr>
              <tr className="tc stripe-light">
                <td className="pa3">Tuesday</td>
                <td className="pa3">{workingHours.tuesday}</td>
              </tr>
              <tr className="tc stripe-light">
                <td className="pa3">Wednesday</td>
                <td className="pa3">{workingHours.wednesday}</td>
              </tr>
              <tr className="tc stripe-light">
                <td className="pa3">Thursday</td>
                <td className="pa3">{workingHours.thursday}</td>
              </tr>
              <tr className="tc stripe-light">
                <td className="pa3">Friday</td>
                <td className="pa3">{workingHours.friday}</td>
              </tr>
              <tr className="tc stripe-light ">
                <td className="pa3">Saturday</td>
                <td className="pa3">{workingHours.saturday}</td>
              </tr>
              <tr className="tc stripe-light">
                <td className="pa3">Sunday</td>
                <td className="pa3">{workingHours.sunday}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  )
}
