import React from "react"
//import { Link } from "gatsby"
import '../styles/main.scss'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
import WorkingHours from '../components/WorkingHours'
import ContactInfo from '../components/ContactInfo'

export default function Contact() {
  return (
    <div className=''>
      <Layout>
        <div className='google-map'>
          <iframe className="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2876.891183490777!2d-79.3342222848737!3d43.85808404717643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d4542c061e29%3A0x61f170e44629101!2sDr.%20Earl%20Winer!5e0!3m2!1sen!2sca!4v1595968983304!5m2!1sen!2sca" width="600" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" title='map'></iframe>
        </div>

        <div className='flex flex-column flex-row-l justify-around-l items-center-l flex-m justify-around-m items-center-m blue-div'>
          <WorkingHours className='w-40-l w-80-m br3 w-90 pa2 fr near-white ma3 ba bw1' />
          <ContactInfo className=' w-40-l w-80-m w-100 mv5 pa3 black bg-white' />
        </div>

        <div className="pa3 ma3 mb0 pb0 tc">        
          <ContactForm />
        </div>

        <div className='flex flex-row-l items-center-l justify-center-l flex-row-m items-center-m justify-center-m flex-column pa3 ma5 mt0 tc'>
          <a className="blue-button b br1 f5 no-underline grow dib v-mid white ba ph3 pv2 mb3" href="mailto:office@drwiner.ca">Email us here!</a>
          <span className='b pa3 h2 dib-l dib-m dn'>or</span>
          <a className="blue-button b br1 f5 no-underline grow dib v-mid white ba ph3 pv2 mb3" href="tel:905-940-9988">Call us here!</a>
        </div>
      </Layout>
    </div>
    )
}

 //<iframe className="dn-l dn-m dib shadow-1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2876.9074416915287!2d-79.33389758401165!3d43.85774707911479!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d455d63b6ac1%3A0xdb1c0e39d3e7401c!2sMarkham%20Town%20Square%20Health%20Centre!5e0!3m2!1sen!2sca!4v1595466518299!5m2!1sen!2sca" width="280" height="300" frameBorder="0" allowFullScreen="" aria-hidden="false" title='map'></iframe>

